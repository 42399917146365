import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'
import { useProjectRoutes } from 'components/ProjectRoutes'
import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'

/**
 */
const Page = () => {
  const routes = useProjectRoutes()
  const { redirectTo } = useNavigate()

  React.useEffect(() => {
    if (routes) {
      redirectTo(routes.toProductList())
    }
  }, [routes])

  return null
}

export default Page

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}
